import * as FilePond from 'filepond';
import * as utils from './galcom-utils.js';


//import 'filepond/dist/filepond.min.css';

console.log("GAL3 this is galcom.js");

frappe.provide("galcom.utils");
window.galcom.utils = utils;
window.FilePond=FilePond;
